.home-slider {
  position: relative;
  @include media-breakpoint-up(lg) {
    padding: 2rem;
    padding-top: 40px;
  }
  @include media-breakpoint-down(lg) {
    text-align: center;
  }
  .slick-dots{
    bottom: 60px;
    li {
      width: 40px;
      position: relative;
      button{
        width: 100%;
      }
    }
    li button:before{
      content: "";
      height: 3px;
      width: 30px;
      background-color: white;
    }
  }

  .slideshow-slide {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    @include media-breakpoint-up(lg) {
      height: 90vh;
    }
    @include media-breakpoint-down(lg) {
      height: 70vh;
      max-height: 450px;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      background-image: linear-gradient(
        180deg,
        #000000 0%,
        rgba(0, 0, 0, 0) 100%
      );
      @include media-breakpoint-up(lg) {

        height: 200px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        0deg,
        #000000 0%,
        rgba(0, 0, 0, 0) 100%
      );
      @include media-breakpoint-up(lg) {

        height: 200px;
      }
    }
    .slide_content {
      position: absolute;
      color: white;
      @include media-breakpoint-down(lg) {
        left: 0 !important;
        right: 0 !important;
        bottom: 10px !important;
        top: unset !important;
      }
      .title,
      .title * {
        font-size: 40px;
        line-height: 1;
        font-family: $title;
        text-shadow: 1px 5px 10px #040404;
        p{
          margin-bottom: 0;
        }
        h2, h1{
          color: white !important;
          text-shadow: 1px 5px 10px #040404;
        }
        @include media-breakpoint-down(lg) {
          font-size: 26px;
          padding: 1rem;
          padding-bottom: 0;

        }
      }
      .slideshow__content{
        @include media-breakpoint-down(lg) {
          line-height: 1.3;
          padding: 1rem;
        }
      }
      &.slide-bottom-left {
        @include media-breakpoint-up(lg) {
          bottom: 60px;
          left: 50px;
        }
      }
      &.slide-bottom-right {
        @include media-breakpoint-up(lg) {
          bottom: 60px;
          right: 50px;
          text-align: right;
        }
      }
      &.slide-bottom-center {
        @include media-breakpoint-up(lg) {
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
        }
      }
      &.slide-center-center {
        @include media-breakpoint-up(lg) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        }
      }
      .slideshow__link {
        color: white;
        span {
          height: 40px;
          width: 40px;
          border: 1px solid skyblue;
          border-radius: 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }
  }
}
.slick-dots li.slick-active button:before{
  color: $secondary;
}
.slick-dots li button:before{
  font-size: 20px;
}
.home_slideshow{
  position: relative;
  z-index: 1;
}
.link_desktop{
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.link_mobile{
  text-align: center;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
.activities_wrapper{
  position: relative;
  @include media-breakpoint-up(lg) {
    padding-bottom: 3rem;
  }
  &::before{
    content: "";
    height: 460px;
    width: 100%;
    display: block;
    background-image: url(../img/bg_wine.jpg);
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: -200px;
  }
  .slick-dots{
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      bottom: -45px;
    }
  }
  .container{
    position: relative;
    z-index: 1;
  }
  @include media-breakpoint-down(lg) {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

}
#id_index {
  h2 {
    font-family: $title;
    color: $dark;
    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 24px;
      text-align: center;
    }
  }
}
.items-wrapper {
  h3 {
    font-family: $title;

  }
  .slick-slide img {
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: $secondary;
    font-family: inherit;
  }
  .slick-arrow {
    border: 1px solid $secondary;
    height: 40px;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    &.slick-disabled {
      border-color: rgba($secondary, 0.3);
    }
  }
  .slick-prev {
    @include media-breakpoint-up(lg) {

      left: -60px;
    }
    @include media-breakpoint-down(lg) {
      left: 20px;
    }
  }
  .slick-next {
    @include media-breakpoint-up(lg) {

      right: -60px;
    }
    @include media-breakpoint-down(lg) {
      right: 20px;
    }
  }
  .slick-arrow{
    @include media-breakpoint-down(lg) {
      top: unset;
      bottom: -40px;
      z-index: 999;
    }
  }
  .item {
    text-align: center;
    &-link {
      display: block;
      position: relative;
    }
    h3 {
      font-size: 22px;
      color: $dark;
      @include media-breakpoint-down(lg) {
        color: white;
        transform: translateY(-150px);
      }
    }
    .thumbnail-image {
      margin: 10px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          #000000 100%
        );
      }
    }
    figure {
      position: relative;
      text-align: center;
      &::after{
        content: "";
        display: block;
        position: absolute;
        border: 1px solid white;
        @include media-breakpoint-up(lg) {

          left: 30px;
          right: 30px;
          top: 30px;
          bottom: 10px;
        }
        border-bottom: 0;
        transition: all .3s ease;
        opacity: 0;
        @include media-breakpoint-down(lg) {
          opacity: 1;
          bottom: 100px;
          left: 10px;
          right: 10px;
          top: 10px;
        }

      }
      figcaption {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .svg-wrapper {
      position: relative;
      top: 50%;
      margin: 0 auto;
      width: 100%;
      transition: all 0.3s ease;
      @include media-breakpoint-up(lg) {
        transform: translateY(-50%);
        opacity: 0;
        transform: translateY(100px);

      }
      @include media-breakpoint-down(lg) {

      }
    }
    .shape {
      stroke-dasharray: 360 14;
      stroke-dashoffset: 274;
      stroke-width: 3px;
      fill: transparent;
      stroke: #fff;
      border-bottom: 5px solid black;
      transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    }
    .text {
      color: #fff;
      top: -42px;
      position: relative;
    }
    .svg-wrapper:hover .shape {
      stroke-width: 2px;
      stroke-dashoffset: 0;
      stroke-dasharray: 760;
    }
    &:hover {
      h3 {
        color: $primary;
      }
      .svg-wrapper {
        opacity: 1;
        transform: translateY(-20px);
      }
      figure:after{
        display: block;
        bottom: 100px;
        opacity: 1;
      }
    }
  }
}

.link{
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &::before{
    content: '';
    width: 20px;
    height: 2px;
    background-color: white;
    display: block;
    margin-right: 10px;
  }
}

section.blog {
  background-color: $dark;
  background-image: url(../img/Vignes_raisins_blancs.png);
  background-size: cover;
  background-blend-mode: overlay;
  .link{
    color: white;
  }
  h2 {
    color: white !important;
  }
  @include media-breakpoint-up(lg) {
    padding: 4rem 0;

  }
  @include media-breakpoint-down(lg) {
    padding: 2rem 0;
    h2{
      text-align: center;
    }
  }
}

.blog-latest-entries {
  @include media-breakpoint-up(lg) {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    .post-item {
      .post-content {
        padding: 2rem;
        background-color: white;
        border-radius: 10px;
      }
      .post-visual {
        border: 1px solid #fff;
      }
      .post-title {
        font-family: $title;
        a {
          color: $dark;
        }
      }
      .author{
        img{
          border-radius: 50%;
          border: 1px solid #eee;
        }
      }
      .post-item-wrapper {
        display: flex;
      }
      &:not(.featured) {
        .post-item-wrapper {
          flex-direction: column;
          position: relative;
          .author{
            position: absolute;
            top: 10px;
            left: 10px;
            span{
              display: none;
            }
          }
          .post-content {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: -50px;
            text-align: center;
            .post-lead {
              display: none;
            }
          }
        }
      }
      &.featured {
        grid-column: 1 / 3;
        .post-item-wrapper {
          align-items: center;
        }
        .post-visual {
          width: 60%;
          img {
            width: 100%;
            height: auto;
          }
        }
        .author{
          padding-top: 1rem;
          margin-top: 1rem;
          border-top: 1px solid #eee;
          font-family: $space_bold;
          a{
            color: $dark;
          }
          img{

            margin-left: 10px;
          }
        }
        .post-content {
          flex: 1;
          margin-left: -60px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .post-item{
      background-color: white;
      margin-bottom: 1rem;
      .post-item-wrapper{
        display: flex;
        padding: .5rem;
        .post-content{
          flex: 1;
          padding-left: 1rem;
        }
        .post-visual{
          width: 40%;
        }
        .read_more, .author{
          display: none;
        }
        .post-header{
          h3{
            a{
              color: $dark;
              font-family: $title;
            }
            font-size: 17px;
            line-height: 20px;
          }
        }
        ul{
          margin-bottom: 0;
        }
        .post-lead {
          display: none;
        }
      }
    }
  }
}

.blog-news {
  @include media-breakpoint-up(lg) {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  .news-item {
    position: relative;
    margin-bottom: 1rem;
  }
  .slick-slide{
    padding: 10px;
  }
  .news-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      #000000 100%
    );

    h3 {
      font-family: $title;
      a {
        color: white;
      }
    }
    .date {
      color: $primary;
      font-family: $title;
      font-size: 22px;
    }
  }
}
.events {
  padding: 4rem 0;
  background-image: url(../img/pattern.png);
  h2{
    margin-bottom: 0;
  }
  .blog-news{
    margin-top: 2rem;
  }
  .link{
    color: $dark;
    &::before{
      background-color: $dark;
    }
  }
}

.social_wall {
  padding: 4rem 0;
}

.newsletter {
  padding: 4rem 0;
  background-color: $dark;
  text-align: center;
  color: white;
  h2 {
    color: white !important;
  }
  .newsletter_link{
    display: inline-flex;
    margin-top: 1rem;
    background-color: $secondary;
    color: white;
    padding: 20px 2.5rem;
    border-radius: 3rem;

    transition: all 0.3s ease;
    &:hover {
      background-color: darken($secondary, 3);
    }
  }
  form {
    display: flex;
    justify-content: center;

    > div {
      @include media-breakpoint-up(lg) {

        background-color: white;
        border-radius: 3rem;
        overflow: hidden;
        padding-left: 1rem;
      }
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        width: 100%;
        input{
          padding: 10px;
          margin-bottom: 10px;
        }
      }
      input[type="email"] {
        border: 0;
        &:focus,
        &:hover {
          outline: none;
        }
      }
      input[type="submit"] {
        background-color: $secondary;
        color: white;
        border: 0;
        @include media-breakpoint-up(lg) {

          padding: 20px 2.5rem;
          border-radius: 3rem;
        }
        transition: all 0.3s ease;
        &:hover {
          background-color: darken($secondary, 3);
        }
      }
    }
  }
}

.activity_address {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  a {
    color: inherit;
    &:hover {
      color: $primary;
    }
  }
  .address {
    display: flex;
    p {
      margin-bottom: 0;
    }
  }
  .activity-contact {
    padding-left: 0;
    list-style-type: none;
    li {
      margin-bottom: 0.5rem;
    }
  }
  .icon {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 20px;
    margin-right: 1rem;
  }
}

.results-list-wrapper {

  .pagination{
    display: flex;
    justify-content: flex-end;
  }
  @include media-breakpoint-down(lg) {
    text-align: center;
    .pagination{
      justify-content: center;
    }
  }
}
.results-wrapper {
  @include media-breakpoint-up(lg) {

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
  @include media-breakpoint-down(lg) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }
  .result {
    background: white;
    border: 1px solid #e5e5e5;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
    position: relative;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      margin-bottom: 3rem;
    }
    .wishlist-controls-wrapper{
      position: absolute;
      top: -10px;
      right: 20px;
      z-index: 9;
      a{
        width: 40px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FAFAFA;
        box-shadow: 0 20px 33px 0 rgba(0,0,0,0.15);
      }
    }
    img {
      border-radius: 10px;
    }
    a {
      display: block;
      color: inherit;
    }
  }
  .thumbnail-image {
    margin-top: -30px;
    margin-bottom: 1rem;
    position: relative;
    &::after{
      width: 90%;
      height: 5px;
      background-color: #ccc;
      opacity: .5;
      bottom: 0;
      left: 50%;
      margin-left: -45%;
      content: "";
      position: absolute;
      bottom: -5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &::before{
      width: 80%;
      height: 10px;
      background-color: #eee;
      bottom: 0;
      left: 50%;
      margin-left: -40%;
      content: "";
      position: absolute;
      bottom: -10px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    figcaption {
      display: none;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .name {
    font-family: $space_bold;
    font-size: 20px;
  }
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.list_map {
  @include media-breakpoint-up(lg) {
    display: flex;
    gap: 1rem;
    background-image: linear-gradient(179deg, rgba(178,178,178,0.27) 0%, #FFFFFF 74%, #FFFFFF 96%);
    position: sticky;
    top: 0;
    .results-list-wrapper{
      width: 60%;
    }
    &.is-fixed{

    }
  }

}


.pagination {
  display: flex;
  .pagination-next,
  .pagination-previous,
  .pagination-link{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #fafafa;
    box-shadow: 0 20px 33px 0 rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    color: inherit;
    transition: all .3s ease;
    &:hover{
      background-color: $secondary;
      color: white;
    }
  }
  .pagination-next{
    margin-left: 10px;
  }
  ul {
    padding-left: 0;
    display: flex;
    list-style: none;

    li {
      margin-left: 10px;
      a.is-current{
        background-color: $secondary;
        color: white;
      }
    }
  }
}


.nav__search {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  a{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    font-family: $space_bold;
    padding: 1rem;
    flex: 1;
    line-height: 1;
    @include media-breakpoint-down(lg) {
      padding: 0;
      .text{
        display: none;
      }
    }
    &.selected{
      color: $secondary;
      .icon {
        border: 2px solid $secondary;
        background: rgba(1,161,174,0.07);
      }
    }
    .icon{
      display: inline-flex;
      width: 60px;
      height: 60px;
      justify-content: center;
      align-items: center;
      background: rgba(122,122,122,0.16);
      border-radius: 30px;
      border:2px solid rgba(122,122,122,0.16);
    }
  }
}


.blog-list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
  header, footer, .featured{
    grid-column: 1 / -1;
  }
  .featured{
    .post-item-wrapper{
      display: flex;
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
      .post-visual{
        @include media-breakpoint-up(lg) {
          min-width: 60%;
        }
        @include media-breakpoint-down(sm) {
          min-width: 100%;
        }
      }
      .post-content{
        display: flex;
        flex-direction: column;
        .author{
          margin-top: auto;
        }
      }
    }
  }
  .post-item-wrapper{
    background: white;
    border: 1px solid #e5e5e5;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .post-visual{
      overflow: hidden;
      padding: 1rem;
      img:not(.post__author){
        border-radius: 10px;
      }
    }
    .post__author{
      border-radius: 50%;
    }
    .read_more{
      margin-top: 1rem;
      text-align: right;
      padding-top: 0.5rem;
      border-top: 1px solid #eee;
      font-family: $space_bold;
    }
    .post-content{
      padding: 1rem;
      .post-title{
        font-size: 20px;
        font-family: $space_bold;
        a{
          color: $secondary;
        }
      }
    }
  }
}
.child{
  .icon{
    display: flex;
  }
}

.wine{
  .icon{
    i{
      @include retina-sprite($sprite-wine-group);
    }
  }
  &.selected{
    .icon{
      i{
        @include retina-sprite($sprite-wine-h-group);
      }
    }
  }
}
.restaurant{
  .icon{
    i{
      @include retina-sprite($sprite-resto-group);
    }
  }
  &.selected{
    .icon{
      i{
        @include retina-sprite($sprite-resto-h-group);
      }
    }
  }
}
.barrel{
  .icon{
    i{
      @include retina-sprite($sprite-barrel-group);
    }
  }
  &.selected{
    .icon{
      i{
        @include retina-sprite($sprite-barrel-h-group);
      }
    }
  }
}
.train{
  .icon{
    i{
      @include retina-sprite($sprite-train-group);
    }
  }
  &.selected{
    .icon{
      i{
        @include retina-sprite($sprite-train-h-group);
      }
    }
  }
}
.accommodations{
  .icon{
    i{
      @include retina-sprite($sprite-bed-group);
    }
  }
  &.selected{
    .icon{
      i{
        @include retina-sprite($sprite-bed-h-group);
      }
    }
  }
}
.hikings{
  .icon{
    i{
      @include retina-sprite($sprite-hiking-group);
    }
  }
  &.selected{
    .icon{
      i{
        @include retina-sprite($sprite-hiking-h-group);
      }
    }
  }
}
.cultural{
  .icon{
    i{
      @include retina-sprite($sprite-patrimoine-group);
    }
  }
  &.selected{
    .icon{
      i{
        @include retina-sprite($sprite-patrimoine-h-group);
      }
    }
  }
}

.icon-add{
  @include retina-sprite($sprite-un-save-group);
  opacity: .2;
  &:hover{
    opacity: 1;
  }
}
.icon-remove{
  @include retina-sprite($sprite-save-group);
}

.icon-selection{
  display: block;
}

.wishlist-controls-wrapper{
  button{
    border-radius: 50%;
    border:0;
    width: 40px;
    height: 40px;
    padding: 0;
  }
}

.social-share-buttons{
  text-align: center;
  a{
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    background-color: $dark;
    margin: 0 0.2rem;
    i{
      color: white;
      font-size: 12px;
    }
  }
}
#id_activity_detail{
  .detail__content{
    position: relative;
    overflow-x: hidden;
    .detail__title{
      >div{
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .wishlist-controls-wrapper {
          margin-right: 20px;
        }
        a.add{
          color: $primary;
        }
        a.remove{
          color: $secondary;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding-top: 120px !important;
      .detail__title{
        position: absolute;
        top: 0;
        right: 20px;
        left: 20px;
        h1{
          font-size: 22px;
        }

      }
    }
  }
}


.form-criteria{
  display: flex;
  padding: 1rem;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  label{
    font-weight: bold;
  }
  p{
    margin-bottom: 0;
  }
  button{
    margin-left: 1rem;
  }
  select, input{
    display: inline-block;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    appearance: none;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    background-color: #f5F5F5;
    text-align: left;
    select, input, p{
      width: 100%;
      display: block;
    }
    p{
      margin-bottom: 1rem;
    }
  }
}
#id_activity_results_list{
  h1{
    margin-top: 1rem;
  }
}

.loading{
  display: none;
  background-color: rgba(black, .7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  span{

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
  }

}
