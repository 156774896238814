html, body {
  height: 100%;
  margin: 0;
}


html{
  scroll-behavior: smooth;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main{
  min-height: 100%;

  &::after{
    content: "";
    display: block;
  }
}

a{
  text-decoration: none;
}
.text-right{
  text-align: right;
}
.btn{
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &-secondary{
    color: white;
  }
}

.breadcrumb{
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  li.is-active a{
    color: inherit;
  }
  li+li {
    padding-left: .5rem;
    &::before{
      float: left;
      padding-right: .5rem;
      color: #6c757d;
      content: "/";
    }
  }
}
body:not(#id_index){
  h1{
    font-family: $title;
    color: $dark;
  }
  h2{
    color: $secondary;

  }
  h2, h3, h4, h5, h6{
    font-family: $space_bold;
  }
}

blockquote {
	position: relative;
	padding-left: 30px;
	font-weight: 900;
	color: $dark;
	padding: 1em;
	margin: 1em;
  margin-bottom: 2rem;
	font-size: 24px;
  font-family: $title;
  font-style: italic;
  letter-spacing: 1px;
	&:before {
		content: "“";
		font-family: serif;
		position: absolute;
		right: 100%;
		font-size: 120px;
		line-height: 0px;
		top: 50px;
		color: $primary;
    opacity: .3;
	}
}


.djangocms-popup{
  top: 50%;
  left: 50%;
  right: inherit;
  bottom: inherit;
  transform: translate(-50%, -50%);
  padding: 10px;
}
.djangocms-popup-close::before{
  content: "X";
  font-weight: bold;
}

.leaflet-top{
  padding-right: 1rem;
}
.leaflet-control-zoom{
  display: flex;
  box-shadow: none;
  a{
    border-radius: 50% !important;
    padding: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin:2px;
    background: #FAFAFA;
    box-shadow: 0 20px 33px 0 rgba(0,0,0,0.15);
    color: #888888;
    width: 26px;
    height: 26px;
    line-height: 26px;
    &:hover{
      display: flex !important;
    }
  }
}




#id_error_page{
  main{
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 10vh;
    background-color: $gray-300;
    color: $dark;
    h1{
      font-family: $title;
    }
    p{
      max-width: 60vw;
      margin-left: auto;
      margin-right: auto;
      font-size: 20px;
    }
    .btn{
      background-color: $primary;
      color: whitesmoke;
      padding: .5rem 1.5rem;
    }
  }
}

.brochure-row {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(220px, 300px));
}
.brochure-item {
  text-align: center;
  margin-bottom: 1rem;
  box-shadow: 1px 1px 60px 1px rgba(black, 0.1);
  .brochure-caption {
    padding: 2rem;
  }
  .brochure-title {
   font-size: 20px;
  }
  img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }
  .brochure-links {
    border-top: 1px dashed #eee;
    display: flex;
    >a{
      padding: 1rem;
      display: inline-block;
      flex:1;
      &:last-child{
        background-color: darken(white,3);
      }
    }
  }
}
