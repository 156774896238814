#id_index {
  .header__site {
    position: absolute;
    z-index: 999;
    @include media-breakpoint-up(lg) {

      padding: 0 3rem;
    }
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    .logo {
      img {
        width: 140px;
        height: auto;
      }
    }
  }
}
body:not(#id_index) {
  .header__bottom {
    background-color: $dark;
  }
}
.header__top {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  font-family: $space_bold;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  a {
    color: $dark;
    font-family: $space_bold;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
  }
  form{
    display: inline-flex;
    align-items: center;
    select{
      font-size: 14px;
      color: $dark !important;
    }
  }
  ul {
    align-items: center;
    li:not(:first-child) {
      margin-left: 15px;
      border-left: 1px solid #eee;
      padding-left: 1rem;
    }
    select {
      appearance: none;
      border: 0;
      color: inherit;
    }
  }
}
.header__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  nav {
    @include media-breakpoint-down(lg) {
      display: none;
    }
    ul {
      display: flex;
      margin-bottom: 0;
      li {
        &.ancestor{
          a{
            color: $secondary;
          }
        }
      }
      li > a {
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        text-decoration: none;
        font-family: $space_bold;
        font-size: 18px;
        &.nav-link{
          &:focus, &:hover{
            color: $secondary;
          }
        }
      }
      .dropdown-toggle::after {
        margin-left: 2px;
        vertical-align: 1px;
      }
    }
  }
}
.mail_icn{
  @include retina-sprite($sprite-mail-group);
}
.heart_icn{
  @include retina-sprite($sprite-heart-group);
}
.webcam_icn{
  @include retina-sprite($sprite-camera-group);
}
.icn{
  display: inline-flex;
  margin-right: 0.3rem;
}
.fr{
  @include retina-sprite($sprite-fr-group);
}
.en{
  @include retina-sprite($sprite-en-group);
}
.wishlist-count{
  background-color: $secondary;
  color: white;
  width: 20px;
  align-items: center;
  height: 20px;
  font-size: 10px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  margin-left: 0.2rem;
}


//burger
.burger_menu{
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
  z-index: 9999;
  @include media-breakpoint-down(lg) {
    display: block;
  }
}
.burger {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin: 5px;
  flex-shrink: 0;
  cursor: pointer;
  background-color: $secondary;
  position: relative;
  z-index: 1;
  .bar {
    display: flex;
    width: 40px;
    height: 5px;
    border-radius: 4px;
    background-color: white;
    margin: 3px 0;
    transition: 0.1s;
  }
  &.active {
    .bar {
        width: 30px;
        border-radius: 20px;
    }
    .bar:nth-child(1) {
        transform: rotateZ(45deg) translate(7.5px, 7.5px);
    }
    .bar:nth-child(2) {
        transform: translateX(0px) rotateZ(45deg);
    }
    .bar:nth-child(3) {
        transform: rotateZ(-45deg) translate(7.5px, -7.5px);
    }
  }
}

.nav_mobile{
  background-color: white;
  box-shadow: 0 2px 34px 0 rgba(0,0,0,0.15);
  border-radius: 12px;
  padding: 1rem;
  padding-bottom: 2rem;
  text-align: center;
  min-width: 300px;
  position: fixed;
  bottom: 60px;
  right: 60px;
  transform-origin: bottom right;
  transition: all .3s ease;
  transform: scale(0);
  &.active{
    transform: scale(1);
  }
  a{
    color: $dark;
    font-family: $space_bold;
  }
}
