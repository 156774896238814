$optin_primary: $secondary;
$optin_slider: $secondary;

.cookieoptin-notice {
  padding: 20px;
  border: 1px solid #fff;
  background-color:black;
  color: white;

  strong {
    display: block;
  }

  a {
    color: $optin_primary;
  }

  .btn {
    box-shadow: none;
    border-radius: 0;
    padding: 10px 15px;
    border: 0;
    border-radius: 0;

    &.btn-success {
      background-color: $optin_primary;
      color: white;
    }

    &.btn-decline {
      border: 1px solid #888;
      color: #444;
      background-color: transparent;
    }
  }
}

#klaro {
  position: relative;
  z-index: 99999;
  .klaro {
    @include media-breakpoint-down(lg) {
      .cn-body{
        *{
          text-align: center !important;
        }
      }
    }
    input:checked + .cm-app-label .slider {
      background-color: $optin_slider;
    }

    p,
    h1,
    h2,
    ul,
    li,
    strong {
      color: #333;
    }

    a {
      color: $optin_primary;
    }

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    /* ---- Btn ----*/
    .cm-btn {
      box-shadow: none;
      border-radius: 0;
      padding: 10px 35px;

      &.cm-btn-success {
        background-color: $optin_primary;
      }

      &.cn-decline {
        border: 1px solid #fff;
        color: #888;
        background-color: transparent;

        &:hover {
          border: 1px solid #ccc;
        }
      }
    }

    .cm-modal {
      background-color: #fff;

      .cm-header,
      .cm-footer {
        border-color: #eee;
      }

      .cm-toggle-all {
        border-top: 0;
      }

      .cm-body {
        padding: 30px;
      }
    }

    .cookie-notice {
      background-color: #fff;
      padding-top: 100px;
      box-shadow: none;
      border-radius: 0;
      border: 1px solid #eee;
      max-width: inherit;
      width: auto;
      right: 20px;

      @include media-breakpoint-down(lg) {
        left: 20px;
        bottom: 20px;

      }



      .cn-ok {
        display: flex;
        @include media-breakpoint-down(lg) {
          flex-direction: column;
          *{
            margin-bottom: 10px;
          }
        }

      }

      .cm-btn-info {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        color: $optin_primary;
      }

      &::before {
        content: "";
        position: absolute;
        background-size: 80%;
        background-position: center center;
        top: 0;
        right: 50%;
        margin-right: -50px;
        height: 100px;
        width: 100px;
        background-image: url("../img/cookie.png");
        background-repeat: no-repeat;
      }
    }
  }
}

.video {
  position: relative;

  .video-player {
    padding: 1rem;
  }
}

.cookieoptin-notice + iframe {
  display: none;
}

.cookieoptin-hide {
  display: none;

  &.cookieoptin-notice + iframe {
    display: block;
  }
}



