@charset "utf-8";

$slick-font-path: "../fonts/";
$slick-loader-path: "../img/";
$fa-font-path: "../fonts/fontawesome/";

@font-face {
  font-family: "GTSectraFine-Bold";
  src: url("../fonts/GT-Sectra-Fine/GT-Sectra-Fine-Bold.woff2") format("woff2"),
    url("../fonts/GT-Sectra-Fine/GT-Sectra-Fine-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

$title: "GTSectraFine-Bold";

@font-face {
  font-family: "SpaceGrotesk-Regular";
  src: url("../fonts/SpaceGrotesk-Regular/SpaceGrotesk-Regular.woff2") format("woff2"),
    url("../fonts/SpaceGrotesk-Regular/SpaceGrotesk-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

$space: "SpaceGrotesk-Regular";

@font-face {
  font-family: "SpaceGrotesk-Bold";
  src: url("../fonts/SpaceGrotesk-Bold/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("../fonts/SpaceGrotesk-Bold/SpaceGrotesk-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

$space_bold: "SpaceGrotesk-Bold";


$font-family-sans-serif: $space, "Segoe UI", Roboto, "Helvetica Neue", Arial;

$primary: #A89971;
$dark : #273243;
$secondary: #01A1AE;
$body-color: #5B5B5B;
