footer.footer_site{
  background-image: url(../img/footer_bg.jpg);
  background-size: cover;
  padding-top: 4rem;
  @include media-breakpoint-down(lg) {
    text-align: center;
  }
  .title{
    font-family: $space_bold;
    color: $primary;
    font-size: 22px;
  }
  .map_img{
    width: 600px;
    max-width: 100%;
  }
  hr{
    background-color: #ddd;
  }
  .nav_f{
    p{
      margin-bottom: 0;
    }
    a{
      color: inherit;
      font-family: $space_bold;
      &:hover{
        color: $secondary;
      }
    }
  }
  .social_links{
    display: flex;
    list-style: none;
    padding-left: 0;
    justify-content: center;
    margin-top: 2rem;
    li{
      margin-left: 10px;
      margin-right: 10px;
      a{
        color: $dark;
        i{
          font-size: 20px;
        }
      }
    }
  }
  .logo_f{
    width: 160px;
    height: auto;

  }
}
.partners-list {
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  li{
    margin-left: 10px;
    margin-right: 10px;
    img{
      mix-blend-mode: multiply;
      max-width: 160px;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 10px;
      img{
        max-width: 60px;
        height: auto;
      }
    }
  }
}

.footer_bottom{
  text-align: center;
  background-color: $dark;
  color: white !important;
  .nav-link{
    color: white !important;
  }
  .nav{
    justify-content: center;
    text-align: center;
    a{
      color: white;
    }
  }
}
