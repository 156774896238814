// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-barrel-h-name: 'sprite_barrel-h';
$sprite-barrel-h-x: 0px;
$sprite-barrel-h-y: 95px;
$sprite-barrel-h-offset-x: 0px;
$sprite-barrel-h-offset-y: -95px;
$sprite-barrel-h-width: 33px;
$sprite-barrel-h-height: 21px;
$sprite-barrel-h-total-width: 153px;
$sprite-barrel-h-total-height: 116px;
$sprite-barrel-h-image: '../img/new_sprite.png';
$sprite-barrel-h: (0px, 95px, 0px, -95px, 33px, 21px, 153px, 116px, '../img/new_sprite.png', 'sprite_barrel-h', );
$sprite-barrel-name: 'sprite_barrel';
$sprite-barrel-x: 33px;
$sprite-barrel-y: 95px;
$sprite-barrel-offset-x: -33px;
$sprite-barrel-offset-y: -95px;
$sprite-barrel-width: 33px;
$sprite-barrel-height: 21px;
$sprite-barrel-total-width: 153px;
$sprite-barrel-total-height: 116px;
$sprite-barrel-image: '../img/new_sprite.png';
$sprite-barrel: (33px, 95px, -33px, -95px, 33px, 21px, 153px, 116px, '../img/new_sprite.png', 'sprite_barrel', );
$sprite-bed-h-name: 'sprite_bed-h';
$sprite-bed-h-x: 96px;
$sprite-bed-h-y: 60px;
$sprite-bed-h-offset-x: -96px;
$sprite-bed-h-offset-y: -60px;
$sprite-bed-h-width: 32px;
$sprite-bed-h-height: 26px;
$sprite-bed-h-total-width: 153px;
$sprite-bed-h-total-height: 116px;
$sprite-bed-h-image: '../img/new_sprite.png';
$sprite-bed-h: (96px, 60px, -96px, -60px, 32px, 26px, 153px, 116px, '../img/new_sprite.png', 'sprite_bed-h', );
$sprite-bed-name: 'sprite_bed';
$sprite-bed-x: 64px;
$sprite-bed-y: 64px;
$sprite-bed-offset-x: -64px;
$sprite-bed-offset-y: -64px;
$sprite-bed-width: 32px;
$sprite-bed-height: 26px;
$sprite-bed-total-width: 153px;
$sprite-bed-total-height: 116px;
$sprite-bed-image: '../img/new_sprite.png';
$sprite-bed: (64px, 64px, -64px, -64px, 32px, 26px, 153px, 116px, '../img/new_sprite.png', 'sprite_bed', );
$sprite-camera-name: 'sprite_camera';
$sprite-camera-x: 129px;
$sprite-camera-y: 0px;
$sprite-camera-offset-x: -129px;
$sprite-camera-offset-y: 0px;
$sprite-camera-width: 24px;
$sprite-camera-height: 24px;
$sprite-camera-total-width: 153px;
$sprite-camera-total-height: 116px;
$sprite-camera-image: '../img/new_sprite.png';
$sprite-camera: (129px, 0px, -129px, 0px, 24px, 24px, 153px, 116px, '../img/new_sprite.png', 'sprite_camera', );
$sprite-en-name: 'sprite_en';
$sprite-en-x: 129px;
$sprite-en-y: 72px;
$sprite-en-offset-x: -129px;
$sprite-en-offset-y: -72px;
$sprite-en-width: 24px;
$sprite-en-height: 18px;
$sprite-en-total-width: 153px;
$sprite-en-total-height: 116px;
$sprite-en-image: '../img/new_sprite.png';
$sprite-en: (129px, 72px, -129px, -72px, 24px, 18px, 153px, 116px, '../img/new_sprite.png', 'sprite_en', );
$sprite-fr-name: 'sprite_fr';
$sprite-fr-x: 129px;
$sprite-fr-y: 90px;
$sprite-fr-offset-x: -129px;
$sprite-fr-offset-y: -90px;
$sprite-fr-width: 24px;
$sprite-fr-height: 18px;
$sprite-fr-total-width: 153px;
$sprite-fr-total-height: 116px;
$sprite-fr-image: '../img/new_sprite.png';
$sprite-fr: (129px, 90px, -129px, -90px, 24px, 18px, 153px, 116px, '../img/new_sprite.png', 'sprite_fr', );
$sprite-heart-name: 'sprite_heart';
$sprite-heart-x: 129px;
$sprite-heart-y: 24px;
$sprite-heart-offset-x: -129px;
$sprite-heart-offset-y: -24px;
$sprite-heart-width: 24px;
$sprite-heart-height: 24px;
$sprite-heart-total-width: 153px;
$sprite-heart-total-height: 116px;
$sprite-heart-image: '../img/new_sprite.png';
$sprite-heart: (129px, 24px, -129px, -24px, 24px, 24px, 153px, 116px, '../img/new_sprite.png', 'sprite_heart', );
$sprite-hiking-h-name: 'sprite_hiking-h';
$sprite-hiking-h-x: 0px;
$sprite-hiking-h-y: 64px;
$sprite-hiking-h-offset-x: 0px;
$sprite-hiking-h-offset-y: -64px;
$sprite-hiking-h-width: 32px;
$sprite-hiking-h-height: 31px;
$sprite-hiking-h-total-width: 153px;
$sprite-hiking-h-total-height: 116px;
$sprite-hiking-h-image: '../img/new_sprite.png';
$sprite-hiking-h: (0px, 64px, 0px, -64px, 32px, 31px, 153px, 116px, '../img/new_sprite.png', 'sprite_hiking-h', );
$sprite-hiking-name: 'sprite_hiking';
$sprite-hiking-x: 32px;
$sprite-hiking-y: 64px;
$sprite-hiking-offset-x: -32px;
$sprite-hiking-offset-y: -64px;
$sprite-hiking-width: 32px;
$sprite-hiking-height: 31px;
$sprite-hiking-total-width: 153px;
$sprite-hiking-total-height: 116px;
$sprite-hiking-image: '../img/new_sprite.png';
$sprite-hiking: (32px, 64px, -32px, -64px, 32px, 31px, 153px, 116px, '../img/new_sprite.png', 'sprite_hiking', );
$sprite-mail-name: 'sprite_mail';
$sprite-mail-x: 129px;
$sprite-mail-y: 48px;
$sprite-mail-offset-x: -129px;
$sprite-mail-offset-y: -48px;
$sprite-mail-width: 24px;
$sprite-mail-height: 24px;
$sprite-mail-total-width: 153px;
$sprite-mail-total-height: 116px;
$sprite-mail-image: '../img/new_sprite.png';
$sprite-mail: (129px, 48px, -129px, -48px, 24px, 24px, 153px, 116px, '../img/new_sprite.png', 'sprite_mail', );
$sprite-patrimoine-h-name: 'sprite_patrimoine-h';
$sprite-patrimoine-h-x: 0px;
$sprite-patrimoine-h-y: 0px;
$sprite-patrimoine-h-offset-x: 0px;
$sprite-patrimoine-h-offset-y: 0px;
$sprite-patrimoine-h-width: 32px;
$sprite-patrimoine-h-height: 32px;
$sprite-patrimoine-h-total-width: 153px;
$sprite-patrimoine-h-total-height: 116px;
$sprite-patrimoine-h-image: '../img/new_sprite.png';
$sprite-patrimoine-h: (0px, 0px, 0px, 0px, 32px, 32px, 153px, 116px, '../img/new_sprite.png', 'sprite_patrimoine-h', );
$sprite-patrimoine-name: 'sprite_patrimoine';
$sprite-patrimoine-x: 32px;
$sprite-patrimoine-y: 0px;
$sprite-patrimoine-offset-x: -32px;
$sprite-patrimoine-offset-y: 0px;
$sprite-patrimoine-width: 32px;
$sprite-patrimoine-height: 32px;
$sprite-patrimoine-total-width: 153px;
$sprite-patrimoine-total-height: 116px;
$sprite-patrimoine-image: '../img/new_sprite.png';
$sprite-patrimoine: (32px, 0px, -32px, 0px, 32px, 32px, 153px, 116px, '../img/new_sprite.png', 'sprite_patrimoine', );
$sprite-resto-h-name: 'sprite_resto-h';
$sprite-resto-h-x: 0px;
$sprite-resto-h-y: 32px;
$sprite-resto-h-offset-x: 0px;
$sprite-resto-h-offset-y: -32px;
$sprite-resto-h-width: 32px;
$sprite-resto-h-height: 32px;
$sprite-resto-h-total-width: 153px;
$sprite-resto-h-total-height: 116px;
$sprite-resto-h-image: '../img/new_sprite.png';
$sprite-resto-h: (0px, 32px, 0px, -32px, 32px, 32px, 153px, 116px, '../img/new_sprite.png', 'sprite_resto-h', );
$sprite-resto-name: 'sprite_resto';
$sprite-resto-x: 32px;
$sprite-resto-y: 32px;
$sprite-resto-offset-x: -32px;
$sprite-resto-offset-y: -32px;
$sprite-resto-width: 32px;
$sprite-resto-height: 32px;
$sprite-resto-total-width: 153px;
$sprite-resto-total-height: 116px;
$sprite-resto-image: '../img/new_sprite.png';
$sprite-resto: (32px, 32px, -32px, -32px, 32px, 32px, 153px, 116px, '../img/new_sprite.png', 'sprite_resto', );
$sprite-save-name: 'sprite_save';
$sprite-save-x: 66px;
$sprite-save-y: 95px;
$sprite-save-offset-x: -66px;
$sprite-save-offset-y: -95px;
$sprite-save-width: 14px;
$sprite-save-height: 18px;
$sprite-save-total-width: 153px;
$sprite-save-total-height: 116px;
$sprite-save-image: '../img/new_sprite.png';
$sprite-save: (66px, 95px, -66px, -95px, 14px, 18px, 153px, 116px, '../img/new_sprite.png', 'sprite_save', );
$sprite-train-h-name: 'sprite_train-h';
$sprite-train-h-x: 96px;
$sprite-train-h-y: 0px;
$sprite-train-h-offset-x: -96px;
$sprite-train-h-offset-y: 0px;
$sprite-train-h-width: 33px;
$sprite-train-h-height: 30px;
$sprite-train-h-total-width: 153px;
$sprite-train-h-total-height: 116px;
$sprite-train-h-image: '../img/new_sprite.png';
$sprite-train-h: (96px, 0px, -96px, 0px, 33px, 30px, 153px, 116px, '../img/new_sprite.png', 'sprite_train-h', );
$sprite-train-name: 'sprite_train';
$sprite-train-x: 96px;
$sprite-train-y: 30px;
$sprite-train-offset-x: -96px;
$sprite-train-offset-y: -30px;
$sprite-train-width: 33px;
$sprite-train-height: 30px;
$sprite-train-total-width: 153px;
$sprite-train-total-height: 116px;
$sprite-train-image: '../img/new_sprite.png';
$sprite-train: (96px, 30px, -96px, -30px, 33px, 30px, 153px, 116px, '../img/new_sprite.png', 'sprite_train', );
$sprite-un-save-name: 'sprite_un-save';
$sprite-un-save-x: 80px;
$sprite-un-save-y: 95px;
$sprite-un-save-offset-x: -80px;
$sprite-un-save-offset-y: -95px;
$sprite-un-save-width: 14px;
$sprite-un-save-height: 18px;
$sprite-un-save-total-width: 153px;
$sprite-un-save-total-height: 116px;
$sprite-un-save-image: '../img/new_sprite.png';
$sprite-un-save: (80px, 95px, -80px, -95px, 14px, 18px, 153px, 116px, '../img/new_sprite.png', 'sprite_un-save', );
$sprite-wine-h-name: 'sprite_wine-h';
$sprite-wine-h-x: 64px;
$sprite-wine-h-y: 0px;
$sprite-wine-h-offset-x: -64px;
$sprite-wine-h-offset-y: 0px;
$sprite-wine-h-width: 32px;
$sprite-wine-h-height: 32px;
$sprite-wine-h-total-width: 153px;
$sprite-wine-h-total-height: 116px;
$sprite-wine-h-image: '../img/new_sprite.png';
$sprite-wine-h: (64px, 0px, -64px, 0px, 32px, 32px, 153px, 116px, '../img/new_sprite.png', 'sprite_wine-h', );
$sprite-wine-name: 'sprite_wine';
$sprite-wine-x: 64px;
$sprite-wine-y: 32px;
$sprite-wine-offset-x: -64px;
$sprite-wine-offset-y: -32px;
$sprite-wine-width: 32px;
$sprite-wine-height: 32px;
$sprite-wine-total-width: 153px;
$sprite-wine-total-height: 116px;
$sprite-wine-image: '../img/new_sprite.png';
$sprite-wine: (64px, 32px, -64px, -32px, 32px, 32px, 153px, 116px, '../img/new_sprite.png', 'sprite_wine', );
$sprite-barrel-h-2x-name: 'sprite_barrel-h@2x';
$sprite-barrel-h-2x-x: 0px;
$sprite-barrel-h-2x-y: 190px;
$sprite-barrel-h-2x-offset-x: 0px;
$sprite-barrel-h-2x-offset-y: -190px;
$sprite-barrel-h-2x-width: 66px;
$sprite-barrel-h-2x-height: 42px;
$sprite-barrel-h-2x-total-width: 306px;
$sprite-barrel-h-2x-total-height: 232px;
$sprite-barrel-h-2x-image: '../img/new_sprite-2x.png';
$sprite-barrel-h-2x: (0px, 190px, 0px, -190px, 66px, 42px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_barrel-h@2x', );
$sprite-barrel-2x-name: 'sprite_barrel@2x';
$sprite-barrel-2x-x: 66px;
$sprite-barrel-2x-y: 190px;
$sprite-barrel-2x-offset-x: -66px;
$sprite-barrel-2x-offset-y: -190px;
$sprite-barrel-2x-width: 66px;
$sprite-barrel-2x-height: 42px;
$sprite-barrel-2x-total-width: 306px;
$sprite-barrel-2x-total-height: 232px;
$sprite-barrel-2x-image: '../img/new_sprite-2x.png';
$sprite-barrel-2x: (66px, 190px, -66px, -190px, 66px, 42px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_barrel@2x', );
$sprite-bed-h-2x-name: 'sprite_bed-h@2x';
$sprite-bed-h-2x-x: 192px;
$sprite-bed-h-2x-y: 120px;
$sprite-bed-h-2x-offset-x: -192px;
$sprite-bed-h-2x-offset-y: -120px;
$sprite-bed-h-2x-width: 64px;
$sprite-bed-h-2x-height: 52px;
$sprite-bed-h-2x-total-width: 306px;
$sprite-bed-h-2x-total-height: 232px;
$sprite-bed-h-2x-image: '../img/new_sprite-2x.png';
$sprite-bed-h-2x: (192px, 120px, -192px, -120px, 64px, 52px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_bed-h@2x', );
$sprite-bed-2x-name: 'sprite_bed@2x';
$sprite-bed-2x-x: 128px;
$sprite-bed-2x-y: 128px;
$sprite-bed-2x-offset-x: -128px;
$sprite-bed-2x-offset-y: -128px;
$sprite-bed-2x-width: 64px;
$sprite-bed-2x-height: 52px;
$sprite-bed-2x-total-width: 306px;
$sprite-bed-2x-total-height: 232px;
$sprite-bed-2x-image: '../img/new_sprite-2x.png';
$sprite-bed-2x: (128px, 128px, -128px, -128px, 64px, 52px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_bed@2x', );
$sprite-camera-2x-name: 'sprite_camera@2x';
$sprite-camera-2x-x: 258px;
$sprite-camera-2x-y: 0px;
$sprite-camera-2x-offset-x: -258px;
$sprite-camera-2x-offset-y: 0px;
$sprite-camera-2x-width: 48px;
$sprite-camera-2x-height: 48px;
$sprite-camera-2x-total-width: 306px;
$sprite-camera-2x-total-height: 232px;
$sprite-camera-2x-image: '../img/new_sprite-2x.png';
$sprite-camera-2x: (258px, 0px, -258px, 0px, 48px, 48px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_camera@2x', );
$sprite-en-2x-name: 'sprite_en@2x';
$sprite-en-2x-x: 258px;
$sprite-en-2x-y: 144px;
$sprite-en-2x-offset-x: -258px;
$sprite-en-2x-offset-y: -144px;
$sprite-en-2x-width: 48px;
$sprite-en-2x-height: 36px;
$sprite-en-2x-total-width: 306px;
$sprite-en-2x-total-height: 232px;
$sprite-en-2x-image: '../img/new_sprite-2x.png';
$sprite-en-2x: (258px, 144px, -258px, -144px, 48px, 36px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_en@2x', );
$sprite-fr-2x-name: 'sprite_fr@2x';
$sprite-fr-2x-x: 258px;
$sprite-fr-2x-y: 180px;
$sprite-fr-2x-offset-x: -258px;
$sprite-fr-2x-offset-y: -180px;
$sprite-fr-2x-width: 48px;
$sprite-fr-2x-height: 36px;
$sprite-fr-2x-total-width: 306px;
$sprite-fr-2x-total-height: 232px;
$sprite-fr-2x-image: '../img/new_sprite-2x.png';
$sprite-fr-2x: (258px, 180px, -258px, -180px, 48px, 36px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_fr@2x', );
$sprite-heart-2x-name: 'sprite_heart@2x';
$sprite-heart-2x-x: 258px;
$sprite-heart-2x-y: 48px;
$sprite-heart-2x-offset-x: -258px;
$sprite-heart-2x-offset-y: -48px;
$sprite-heart-2x-width: 48px;
$sprite-heart-2x-height: 48px;
$sprite-heart-2x-total-width: 306px;
$sprite-heart-2x-total-height: 232px;
$sprite-heart-2x-image: '../img/new_sprite-2x.png';
$sprite-heart-2x: (258px, 48px, -258px, -48px, 48px, 48px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_heart@2x', );
$sprite-hiking-h-2x-name: 'sprite_hiking-h@2x';
$sprite-hiking-h-2x-x: 0px;
$sprite-hiking-h-2x-y: 128px;
$sprite-hiking-h-2x-offset-x: 0px;
$sprite-hiking-h-2x-offset-y: -128px;
$sprite-hiking-h-2x-width: 64px;
$sprite-hiking-h-2x-height: 62px;
$sprite-hiking-h-2x-total-width: 306px;
$sprite-hiking-h-2x-total-height: 232px;
$sprite-hiking-h-2x-image: '../img/new_sprite-2x.png';
$sprite-hiking-h-2x: (0px, 128px, 0px, -128px, 64px, 62px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_hiking-h@2x', );
$sprite-hiking-2x-name: 'sprite_hiking@2x';
$sprite-hiking-2x-x: 64px;
$sprite-hiking-2x-y: 128px;
$sprite-hiking-2x-offset-x: -64px;
$sprite-hiking-2x-offset-y: -128px;
$sprite-hiking-2x-width: 64px;
$sprite-hiking-2x-height: 62px;
$sprite-hiking-2x-total-width: 306px;
$sprite-hiking-2x-total-height: 232px;
$sprite-hiking-2x-image: '../img/new_sprite-2x.png';
$sprite-hiking-2x: (64px, 128px, -64px, -128px, 64px, 62px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_hiking@2x', );
$sprite-mail-2x-name: 'sprite_mail@2x';
$sprite-mail-2x-x: 258px;
$sprite-mail-2x-y: 96px;
$sprite-mail-2x-offset-x: -258px;
$sprite-mail-2x-offset-y: -96px;
$sprite-mail-2x-width: 48px;
$sprite-mail-2x-height: 48px;
$sprite-mail-2x-total-width: 306px;
$sprite-mail-2x-total-height: 232px;
$sprite-mail-2x-image: '../img/new_sprite-2x.png';
$sprite-mail-2x: (258px, 96px, -258px, -96px, 48px, 48px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_mail@2x', );
$sprite-patrimoine-h-2x-name: 'sprite_patrimoine-h@2x';
$sprite-patrimoine-h-2x-x: 0px;
$sprite-patrimoine-h-2x-y: 0px;
$sprite-patrimoine-h-2x-offset-x: 0px;
$sprite-patrimoine-h-2x-offset-y: 0px;
$sprite-patrimoine-h-2x-width: 64px;
$sprite-patrimoine-h-2x-height: 64px;
$sprite-patrimoine-h-2x-total-width: 306px;
$sprite-patrimoine-h-2x-total-height: 232px;
$sprite-patrimoine-h-2x-image: '../img/new_sprite-2x.png';
$sprite-patrimoine-h-2x: (0px, 0px, 0px, 0px, 64px, 64px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_patrimoine-h@2x', );
$sprite-patrimoine-2x-name: 'sprite_patrimoine@2x';
$sprite-patrimoine-2x-x: 64px;
$sprite-patrimoine-2x-y: 0px;
$sprite-patrimoine-2x-offset-x: -64px;
$sprite-patrimoine-2x-offset-y: 0px;
$sprite-patrimoine-2x-width: 64px;
$sprite-patrimoine-2x-height: 64px;
$sprite-patrimoine-2x-total-width: 306px;
$sprite-patrimoine-2x-total-height: 232px;
$sprite-patrimoine-2x-image: '../img/new_sprite-2x.png';
$sprite-patrimoine-2x: (64px, 0px, -64px, 0px, 64px, 64px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_patrimoine@2x', );
$sprite-resto-h-2x-name: 'sprite_resto-h@2x';
$sprite-resto-h-2x-x: 0px;
$sprite-resto-h-2x-y: 64px;
$sprite-resto-h-2x-offset-x: 0px;
$sprite-resto-h-2x-offset-y: -64px;
$sprite-resto-h-2x-width: 64px;
$sprite-resto-h-2x-height: 64px;
$sprite-resto-h-2x-total-width: 306px;
$sprite-resto-h-2x-total-height: 232px;
$sprite-resto-h-2x-image: '../img/new_sprite-2x.png';
$sprite-resto-h-2x: (0px, 64px, 0px, -64px, 64px, 64px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_resto-h@2x', );
$sprite-resto-2x-name: 'sprite_resto@2x';
$sprite-resto-2x-x: 64px;
$sprite-resto-2x-y: 64px;
$sprite-resto-2x-offset-x: -64px;
$sprite-resto-2x-offset-y: -64px;
$sprite-resto-2x-width: 64px;
$sprite-resto-2x-height: 64px;
$sprite-resto-2x-total-width: 306px;
$sprite-resto-2x-total-height: 232px;
$sprite-resto-2x-image: '../img/new_sprite-2x.png';
$sprite-resto-2x: (64px, 64px, -64px, -64px, 64px, 64px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_resto@2x', );
$sprite-save-2x-name: 'sprite_save@2x';
$sprite-save-2x-x: 132px;
$sprite-save-2x-y: 190px;
$sprite-save-2x-offset-x: -132px;
$sprite-save-2x-offset-y: -190px;
$sprite-save-2x-width: 28px;
$sprite-save-2x-height: 36px;
$sprite-save-2x-total-width: 306px;
$sprite-save-2x-total-height: 232px;
$sprite-save-2x-image: '../img/new_sprite-2x.png';
$sprite-save-2x: (132px, 190px, -132px, -190px, 28px, 36px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_save@2x', );
$sprite-train-h-2x-name: 'sprite_train-h@2x';
$sprite-train-h-2x-x: 192px;
$sprite-train-h-2x-y: 0px;
$sprite-train-h-2x-offset-x: -192px;
$sprite-train-h-2x-offset-y: 0px;
$sprite-train-h-2x-width: 66px;
$sprite-train-h-2x-height: 60px;
$sprite-train-h-2x-total-width: 306px;
$sprite-train-h-2x-total-height: 232px;
$sprite-train-h-2x-image: '../img/new_sprite-2x.png';
$sprite-train-h-2x: (192px, 0px, -192px, 0px, 66px, 60px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_train-h@2x', );
$sprite-train-2x-name: 'sprite_train@2x';
$sprite-train-2x-x: 192px;
$sprite-train-2x-y: 60px;
$sprite-train-2x-offset-x: -192px;
$sprite-train-2x-offset-y: -60px;
$sprite-train-2x-width: 66px;
$sprite-train-2x-height: 60px;
$sprite-train-2x-total-width: 306px;
$sprite-train-2x-total-height: 232px;
$sprite-train-2x-image: '../img/new_sprite-2x.png';
$sprite-train-2x: (192px, 60px, -192px, -60px, 66px, 60px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_train@2x', );
$sprite-un-save-2x-name: 'sprite_un-save@2x';
$sprite-un-save-2x-x: 160px;
$sprite-un-save-2x-y: 190px;
$sprite-un-save-2x-offset-x: -160px;
$sprite-un-save-2x-offset-y: -190px;
$sprite-un-save-2x-width: 28px;
$sprite-un-save-2x-height: 36px;
$sprite-un-save-2x-total-width: 306px;
$sprite-un-save-2x-total-height: 232px;
$sprite-un-save-2x-image: '../img/new_sprite-2x.png';
$sprite-un-save-2x: (160px, 190px, -160px, -190px, 28px, 36px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_un-save@2x', );
$sprite-wine-h-2x-name: 'sprite_wine-h@2x';
$sprite-wine-h-2x-x: 128px;
$sprite-wine-h-2x-y: 0px;
$sprite-wine-h-2x-offset-x: -128px;
$sprite-wine-h-2x-offset-y: 0px;
$sprite-wine-h-2x-width: 64px;
$sprite-wine-h-2x-height: 64px;
$sprite-wine-h-2x-total-width: 306px;
$sprite-wine-h-2x-total-height: 232px;
$sprite-wine-h-2x-image: '../img/new_sprite-2x.png';
$sprite-wine-h-2x: (128px, 0px, -128px, 0px, 64px, 64px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_wine-h@2x', );
$sprite-wine-2x-name: 'sprite_wine@2x';
$sprite-wine-2x-x: 128px;
$sprite-wine-2x-y: 64px;
$sprite-wine-2x-offset-x: -128px;
$sprite-wine-2x-offset-y: -64px;
$sprite-wine-2x-width: 64px;
$sprite-wine-2x-height: 64px;
$sprite-wine-2x-total-width: 306px;
$sprite-wine-2x-total-height: 232px;
$sprite-wine-2x-image: '../img/new_sprite-2x.png';
$sprite-wine-2x: (128px, 64px, -128px, -64px, 64px, 64px, 306px, 232px, '../img/new_sprite-2x.png', 'sprite_wine@2x', );
$spritesheet-width: 153px;
$spritesheet-height: 116px;
$spritesheet-image: '../img/new_sprite.png';
$spritesheet-sprites: ($sprite-barrel-h, $sprite-barrel, $sprite-bed-h, $sprite-bed, $sprite-camera, $sprite-en, $sprite-fr, $sprite-heart, $sprite-hiking-h, $sprite-hiking, $sprite-mail, $sprite-patrimoine-h, $sprite-patrimoine, $sprite-resto-h, $sprite-resto, $sprite-save, $sprite-train-h, $sprite-train, $sprite-un-save, $sprite-wine-h, $sprite-wine, );
$spritesheet: (153px, 116px, '../img/new_sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 306px;
$retina-spritesheet-height: 232px;
$retina-spritesheet-image: '../img/new_sprite-2x.png';
$retina-spritesheet-sprites: ($sprite-barrel-h-2x, $sprite-barrel-2x, $sprite-bed-h-2x, $sprite-bed-2x, $sprite-camera-2x, $sprite-en-2x, $sprite-fr-2x, $sprite-heart-2x, $sprite-hiking-h-2x, $sprite-hiking-2x, $sprite-mail-2x, $sprite-patrimoine-h-2x, $sprite-patrimoine-2x, $sprite-resto-h-2x, $sprite-resto-2x, $sprite-save-2x, $sprite-train-h-2x, $sprite-train-2x, $sprite-un-save-2x, $sprite-wine-h-2x, $sprite-wine-2x, );
$retina-spritesheet: (306px, 232px, '../img/new_sprite-2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$sprite-barrel-h-group-name: 'sprite_barrel-h';
$sprite-barrel-h-group: ('sprite_barrel-h', $sprite-barrel-h, $sprite-barrel-h-2x, );
$sprite-barrel-group-name: 'sprite_barrel';
$sprite-barrel-group: ('sprite_barrel', $sprite-barrel, $sprite-barrel-2x, );
$sprite-bed-h-group-name: 'sprite_bed-h';
$sprite-bed-h-group: ('sprite_bed-h', $sprite-bed-h, $sprite-bed-h-2x, );
$sprite-bed-group-name: 'sprite_bed';
$sprite-bed-group: ('sprite_bed', $sprite-bed, $sprite-bed-2x, );
$sprite-camera-group-name: 'sprite_camera';
$sprite-camera-group: ('sprite_camera', $sprite-camera, $sprite-camera-2x, );
$sprite-en-group-name: 'sprite_en';
$sprite-en-group: ('sprite_en', $sprite-en, $sprite-en-2x, );
$sprite-fr-group-name: 'sprite_fr';
$sprite-fr-group: ('sprite_fr', $sprite-fr, $sprite-fr-2x, );
$sprite-heart-group-name: 'sprite_heart';
$sprite-heart-group: ('sprite_heart', $sprite-heart, $sprite-heart-2x, );
$sprite-hiking-h-group-name: 'sprite_hiking-h';
$sprite-hiking-h-group: ('sprite_hiking-h', $sprite-hiking-h, $sprite-hiking-h-2x, );
$sprite-hiking-group-name: 'sprite_hiking';
$sprite-hiking-group: ('sprite_hiking', $sprite-hiking, $sprite-hiking-2x, );
$sprite-mail-group-name: 'sprite_mail';
$sprite-mail-group: ('sprite_mail', $sprite-mail, $sprite-mail-2x, );
$sprite-patrimoine-h-group-name: 'sprite_patrimoine-h';
$sprite-patrimoine-h-group: ('sprite_patrimoine-h', $sprite-patrimoine-h, $sprite-patrimoine-h-2x, );
$sprite-patrimoine-group-name: 'sprite_patrimoine';
$sprite-patrimoine-group: ('sprite_patrimoine', $sprite-patrimoine, $sprite-patrimoine-2x, );
$sprite-resto-h-group-name: 'sprite_resto-h';
$sprite-resto-h-group: ('sprite_resto-h', $sprite-resto-h, $sprite-resto-h-2x, );
$sprite-resto-group-name: 'sprite_resto';
$sprite-resto-group: ('sprite_resto', $sprite-resto, $sprite-resto-2x, );
$sprite-save-group-name: 'sprite_save';
$sprite-save-group: ('sprite_save', $sprite-save, $sprite-save-2x, );
$sprite-train-h-group-name: 'sprite_train-h';
$sprite-train-h-group: ('sprite_train-h', $sprite-train-h, $sprite-train-h-2x, );
$sprite-train-group-name: 'sprite_train';
$sprite-train-group: ('sprite_train', $sprite-train, $sprite-train-2x, );
$sprite-un-save-group-name: 'sprite_un-save';
$sprite-un-save-group: ('sprite_un-save', $sprite-un-save, $sprite-un-save-2x, );
$sprite-wine-h-group-name: 'sprite_wine-h';
$sprite-wine-h-group: ('sprite_wine-h', $sprite-wine-h, $sprite-wine-h-2x, );
$sprite-wine-group-name: 'sprite_wine';
$sprite-wine-group: ('sprite_wine', $sprite-wine, $sprite-wine-2x, );
$retina-groups: ($sprite-barrel-h-group, $sprite-barrel-group, $sprite-bed-h-group, $sprite-bed-group, $sprite-camera-group, $sprite-en-group, $sprite-fr-group, $sprite-heart-group, $sprite-hiking-h-group, $sprite-hiking-group, $sprite-mail-group, $sprite-patrimoine-h-group, $sprite-patrimoine-group, $sprite-resto-h-group, $sprite-resto-group, $sprite-save-group, $sprite-train-h-group, $sprite-train-group, $sprite-un-save-group, $sprite-wine-h-group, $sprite-wine-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
